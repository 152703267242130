import Repository from "../Repository"

interface logType {
  date: string,
  ip: string,
  ua: string,
}

const postResource = '/log'
const LogRepository = {
  async post(params: logType) {
    const res = await Repository.post(postResource, params)
    return res
  }
}

export { LogRepository }
