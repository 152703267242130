
  import { defineComponent, ref } from 'vue'
  import VideoPlayer from '../components/VideoPlayer.vue'
  import { getRepositories } from '../repositories/RepositoryFactory'

  export default defineComponent({
    components: {
      VideoPlayer,
    },
    props: {
      id: {
        type: String,
        required: false,
        default: () => '',
      },
    },
    setup() {
      const videoPlayer = ref()
      const repositories = getRepositories()
      const videos = repositories.videos.get()

      const authenticated = ref(false)
      const authKey = ref('')
      const checkAuthKey = () => {
        if (process.env.VUE_APP_AUTH_KEY === authKey.value) {
          authenticated.value = true
          logging()
        } else {
          window.alert('認証キーが違います。')
        }
      }

      const logging = async () => {
        const ua = window.navigator.userAgent.toLowerCase()
        const ip = await findIp()
        const date = new Date()
        const dateString = date.toDateString()
        await repositories.log.post({
          ua,
          ip,
          date: dateString,
        })
      }

      const findIp = async () => {
        try {
          const res = await repositories.ip.get()
          return res.data.ip
        } catch (err) {
          console.log(err)
          return false
        }
      }

      return {
        videos,
        videoPlayer,
        authenticated,
        checkAuthKey,
        authKey,
      }
    },
  })
