<template>
  <header class="header">
    <a href="/">
      <span class="header--title">出版UD研究会</span>
    </a>
  </header>
</template>

<style lang="scss" scoped>
  .header {
    height: 72px;
    margin: 0 0 5px 0;
    background: #1b2538;

    a {
      display: block;
      max-height: 100%;
      max-width: 20%;
      margin: 0 10px;
      padding: 5px;
      text-decoration: none;

      .header--title {
        color: #ffffff;
        font-size: 2.5rem;
      }

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
</style>
