import { VideoRepository } from './Video/VideoRepository'
import { LogRepository } from './Log/LogRepository'
import { IpRepository } from './Ip/IpRepository'

interface Repositories {
  videos: typeof VideoRepository,
  log: typeof LogRepository,
  ip: typeof IpRepository,
}

const getRepositories = (): Repositories => {
  const repositories: Repositories = {
    videos: VideoRepository,
    log: LogRepository,
    ip: IpRepository
  }
  return repositories
}

export { getRepositories }
