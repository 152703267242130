
  import { defineComponent, ref } from 'vue'
  import VideoPlayer from '../components/VideoPlayer.vue'
  import { getRepositories } from '../repositories/RepositoryFactory'

  export default defineComponent({
    components: {
      VideoPlayer,
    },
    props: {
      id: {
        type: String,
        required: false,
        default: () => '',
      },
    },
    setup() {
      const videoPlayer = ref()
      const repositories = getRepositories()
      const videos = repositories.videos.get()
      const video = ref(videos[0])

      return {
        video,
        videoPlayer,
      }
    },
  })
