const videos = [
  {
    title: '「エディトリアルUD」5つの視点',
    id: 'udpub62_E-UD_A1',
    url: 'https://pub-ud.tokyo-book.net/videos/udpub62_E-UD_A1.mp4',
    isPaid: false
  },
  {
    title: 'UDコミュニケーションラボについて',
    id: 'udpub62_E-UD_A2',
    url: 'https://pub-ud.tokyo-book.net/videos/udpub62_E-UD_A2.mp4',
    isPaid: true
  },
  {
    title: '情報提供におけるユニバーサルデザイン',
    id: 'udpub62_E-UD_A3',
    url: 'https://pub-ud.tokyo-book.net/videos/udpub62_E-UD_A3.mp4',
    isPaid: true
  },
  {
    title: '本日のおしながき',
    id: 'udpub62_E-UD_B1',
    url: 'https://pub-ud.tokyo-book.net/videos/udpub62_E-UD_B1.mp4',
    isPaid: true
  },
  {
    title: '配慮したいポイントの例',
    id: 'udpub62_E-UD_B2',
    url: 'https://pub-ud.tokyo-book.net/videos/udpub62_E-UD_B2.mp4',
    isPaid: true
  },
  {
    title: 'プレインワーディング',
    id: 'udpub62_E-UD_B3',
    url: 'https://pub-ud.tokyo-book.net/videos/udpub62_E-UD_B3.mp4',
    isPaid: true
  },
]

const VideoRepository = {
  get() {
    return videos
  }
}

export { VideoRepository }
