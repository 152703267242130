<template>
  <div class="player">
    <video ref="videoPlayer" class="vjs-big-play-centered video-js"></video>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref } from 'vue'
  import videojs from 'video.js'

  export default defineComponent({
    props: {
      video: {
        required: true,
        type: Object,
      },
    },
    setup: (props) => {
      const videoPlayer = ref(null)
      const player = ref(null)

      onMounted(() => {
        const options = {
          autoplay: false,
          controls: true,
          width: '100%',
          height: '100%',
          sources: {
            type: 'video/mp4',
            src: props.video.url,
          },
        }

        player.value = videojs(videoPlayer.value, options, () => {
          console.log('onPlayerReady')
        })
      })

      return {
        videoPlayer,
        player,
      }
    },
  })
</script>

<style lang="scss" scoped>
  @import url('https://unpkg.com/video.js@7.3.0/dist/video-js.min.css');

  .player {
    width: 50%;
    border: solid black 1px;

    .video-js {
      width: 100%;
      height: 500px;
    }
  }

  @media screen and (max-width: 768px) {
    .player {
      width: 98%;

      .video-js {
        width: 100%;
        height: 250px;
      }
    }
  }
</style>
